import React, { useEffect, useState } from 'react'
import { AppHeader, AppSidebar } from '../components/index'
import Joyride from 'react-joyride'
import Dashboard from 'src/views/dashboard/Dashboard'
import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import CookieConsent from 'react-cookie-consent'
import { useSelector, useDispatch } from 'react-redux'
import { setRunTour } from 'src/redux/actions/StateAction'
import Diagram from '../assets/Diagram_list.png'
import mermaid from 'mermaid'

const DefaultLayout = () => {
  const customStyles = {
    options: {
      arrowColor: '#e3ffeb',
      backgroundColor: '#e3ffeb',
      primaryColor: '#000',
      textColor: '#004a14',
      zIndex: 1000,
    },
    buttonNext: {
      background: '#5C8374',
    },
  }

  const { user, isAuthenticated, isLoading, loginWithRedirect, logout, getAccessTokenSilently } =
    useAuth0()
  const [visible, setVisible] = useState(false)
  const navigate = useNavigate()
  let runTour = useSelector((state) => state.stateReducer.setRunTour)
  const dispatch = useDispatch()
  const steps = [
    {
      target: '.body',
      title: 'Welcome to Code2Diagram',
      content: (
        <div color="#606060">
          Revolutionize cloud diagrams at 10x speed with Code 2 Diagram. Tour now for endless
          innovation!
        </div>
      ),
      locale: {
        next: 'Take the tour',
        color: '#FFF;',
        skip: (
          <i color="#626262" aria-label="skip">
            Skip
          </i>
        ),
      },
      showProgress: false,
      placement: 'center',
    },
    {
      target: '.diagram-list',
      content: (
        <div color="#606060">
          <span>Choose the type of diagram from the list</span>
          <img src={Diagram} alt="Diagram_list" ></img>
        </div>
      ),
      locale: {
        back: null,
        next: 'Next',
        skip: (
          <i color="#626262" aria-label="skip">
            Skip
          </i>
        ),
      },
    },
    {
      target: '.editor-class',
      content: (
        <div color="#606060">
          Please make the necessary modifications to the code and add your requirements
        </div>
      ),
      locale: {
        back: null,
        next: 'Next',
        skip: (
          <i color="#626262" aria-label="skip">
            Skip
          </i>
        ),
      },
    },

    {
      target: '.image-style',
      content: <div color="#606060">Discover your diagram here</div>,
      locale: {
        back: null,
        next: 'Next',
        skip: (
          <i color="#626262" aria-label="skip">
            Skip
          </i>
        ),
      },
    },
    {
      target: '.download-button',
      content: <div color="#606060">Great! Your diagram is ready. Please download it from here</div>,
      locale: {
        back: null,
        skip: (
          <i color="#626262" aria-label="skip">
            Skip
          </i>
        ),
      },
    },
  ]

  function initMermaid(){
    var flowchart = "flowchart TB"
    mermaid.render('graph', flowchart)
  }

  const clearServiceWorkerCache = async () => {
    if ('serviceWorker' in navigator) {
      const registrations = await navigator.serviceWorker.getRegistrations();
      for (let registration of registrations) {
        registration.unregister();
      }
      
      if (window.caches) {
        const cacheNames = await caches.keys();
        await Promise.all(
          cacheNames.map((cacheName) => caches.delete(cacheName))
        );
      }
    }
  };

  useEffect(() => {
    initMermaid();
    if (localStorage.getItem('202408_v19') !== 'CLEARED') {
      clearServiceWorkerCache()
      localStorage.clear()
      sessionStorage.clear()
      localStorage.setItem('202408_v19', 'CLEARED')
    }
  }, [])

  const handleStartTour = () => {
    dispatch(setRunTour(true))
  }

  function manageLogin() {
    const queryParams = new URLSearchParams(window.location.search)
    if (queryParams.get('error') && queryParams.get('error_description')) {
      logout()
    }
    loginWithRedirect()
  }

  async function fetchUser() {
    if (sessionStorage.getItem('userFetchedEmail')) {
      navigate('/pricing')
      return
    }
    const token = await getAccessTokenSilently()
    if (token && user) {
      var userId = user.sub
      userId = userId.replace('|', '%7C')
      axios
        .get(`${process.env.REACT_APP_SIDEBAR_API_URL}/api/diagram/user/get/` + userId, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const result = response.data.result
          if (result && result.length > 0) {
            sessionStorage.setItem('userFetched', JSON.stringify(result[0]))
            sessionStorage.setItem('userFetchedId', result[0].userId)
            sessionStorage.setItem('userFetchedEmail', result[0].email)
            navigate('/pricing')
          }
        })
        .catch((err) => {
          console.log('Error occured ' + err.msg)
        })
    }
  }

  function handleUpgrade() {
    if (isAuthenticated) {
      setVisible(!visible)
      fetchUser()
    } else {
      sessionStorage.setItem('NAVIGATE_TO', '/pricing')
      manageLogin()
    }
  }
  return (
    <div className="base body">
      <div>
        <Joyride
          continuous
          run={runTour}
          steps={steps}
          hideCloseButton
          scrollToFirstStep
          showSkipButton
          showProgress
          styles={customStyles}
          callback={(data) => {
            if (data.action === 'reset') {
              dispatch(setRunTour(false))
              localStorage.setItem('shouldSeeTour', 'false')
            }
          }}
        />
        <AppSidebar />
        <div className="wrapper flex flex-column overflow-hidden">
          <AppHeader
            handleStartTour={handleStartTour}
            user={user}
            isAuthenticated={isAuthenticated}
            logout={logout}
            getAccessTokenSilently={getAccessTokenSilently}
            handleUpgrade={handleUpgrade}
            manageLogin={manageLogin}
          />
          <Dashboard
            handleUpgrade={handleUpgrade}
            user={user}
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            getAccessTokenSilently={getAccessTokenSilently}
            loginWithRedirect={loginWithRedirect}
          />
        </div>

        <CookieConsent style={{ backgroundColor: '#183d3d', zIndex: '11' }}>
          <span style={{ paddingLeft: '250px' }}>
            This website uses cookies to enhance the user experience.
          </span>
        </CookieConsent>
      </div>
    </div>
  )
}

export default DefaultLayout
