import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import mermaid from 'mermaid'
import { PanZoom } from 'react-easy-panzoom'
import { CONSTANTS } from '../Constants'
import { MapContainer } from 'react-leaflet'
import { CircularProgress } from '@mui/material'

const ClassDiagram = ({
  direction,
  panZoomRef,
  setOpenSnackbar,
  setSnackbarMessage,
  setSnackbarSeverity,
  setOpenSnackbarDiagram,
}) => {
  let editorValue = useSelector((state) => state.classEditor.classEditorValue)
  const [classImage, setClassImage] = useState('')
  const [loading, setLoading] = useState(false)

  const classCompiler = (classDiagramStr, direction) => {
    return `classDiagram \n direction ${direction}\n${classDiagramStr}`
  }

  const generateClassDiagram = async (classDiagram) => {
    setLoading(true)
    setOpenSnackbarDiagram(true)
    try {
      mermaid.initialize({
        theme: 'base',
        themeVariables: {
          primaryColor: 'lightyellow',
          primaryTextColor: '#000',
          primaryBorderColor: '#000',
          lineColor: 'red',
        },
      })

      var classData = classCompiler(classDiagram, direction)
      classData = '\n' + classData

      const result = await mermaid.render('graph', classData)
      let svgText = result.svg
      let downloadSvgText = result.svg
      if (sessionStorage.getItem('isSubscribed') !== 'true') {
        downloadSvgText = downloadSvgText.replace(
          '</style>',
          `</style>
      <text transform='translate(10, 20)' fill='rgba(45,45,45,0.2)' font-size='20'>code2diagram.com</text>`,
        )
      }

      localStorage.setItem(
        'CLASS_DIAGRAM_IMAGE',
        `data:image/svg+xml,${encodeURIComponent(svgText)}`,
      )
      sessionStorage.setItem('DOWNLOAD_CLASS_IMAGE', `data:image/svg+xml,${encodeURIComponent(downloadSvgText)}`)

      let res = svgText
      setLoading(false)
      setOpenSnackbarDiagram(false)
      return res
    } catch (error) {
      console.log('error', error)
      setLoading(false)
      setOpenSnackbarDiagram(false)
      setOpenSnackbar(true)
      setSnackbarMessage('Compilation failed. Invalid input format')
      setSnackbarSeverity('error')
      return null
    }
  }

  useEffect(() => {
    if (panZoomRef.current) {
      panZoomRef.current.setOptions({ preventScroll: true });
    }
  }, [panZoomRef]);

  useEffect(() => {
    const generateClassImage = async () => {
      if (editorValue.trim() === '') {
        setClassImage('')
        setLoading(false)
        return
      }
      try {
        const outputImage = await generateClassDiagram(editorValue)
        if (outputImage != null) {
          setLoading(false)
          setClassImage(outputImage)
        } else if (outputImage == null && classImage == '') {
          setLoading(true)
        }
      } catch (error) {
        console.error('Error generating class diagram:', error)
        return null
      }
    }
    generateClassImage()
    localStorage.setItem('selectedMode', CONSTANTS.CLASS_DIAGRAM)
  }, [editorValue, direction])

  return (
    <div className="alignMiddle" style={{ overflow: 'auto' }}>
      <MapContainer
        center={[51.505, -0.09]}
        zoom={13}
        attributionControl={false}
        zoomControl={false}
      >
        <PanZoom ref={panZoomRef}>
          {loading && (
            <div className="alignMiddle">
              <CircularProgress />
              Executing...
            </div>
          )}
          {!loading &&(classImage? (
            <img
              src={`data:image/svg+xml,${encodeURIComponent(classImage)}`}
              alt="Class Diagram"
              className="image-style"
            />
          ) : (
            <div style={{ color: 'gray', fontSize: '24px' }}></div>
          ))}
        </PanZoom>
      </MapContainer>
    </div>
  )
}

export default ClassDiagram
