import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import {
  CFormInput,
  CFormSelect,
  CModal,
  CModalBody,
  CModalFooter,
  CModalTitle,
} from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { Button, Tooltip } from '@mui/material'

const ShareModal = ({
  visibleShare,
  setVisibleShare,
  setFilename,
  handleSelectedTeam,
  selectedEmail,
  teamName,
  setTeamName,
  shareCodeToTeam,
  filename,
  direction,
  diagramName,
  teamList,
  tag,
  setTag,
  generatedLink,
  CopyFunction,
  handleShareDiagram,
  setGeneratedLink,
  isAuthenticated,
}) => {
  const [selectedOption, setSelectedOption] = useState('file')
  const [selectedTeam, setSelectedTeam] = useState('')

  const handleTagChange = (newTag) => {
    const newEmails = newTag.split(/[, \n]+/).map((tag) => tag.trim())
    setTag(newEmails)
  }

  const handleInputKeyDown = (e) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      const cursorPosition = e.target.selectionStart
      const newValue = e.target.value

      if (newValue[cursorPosition - 2] === ',' && newValue[cursorPosition - 1] === ' ') {
        e.preventDefault()
        const updatedValue = newValue.slice(0, cursorPosition - 2) + newValue.slice(cursorPosition)
        handleTagChange(updatedValue)
      }
    }
  }

  useEffect(() => {
    if (!visibleShare) {
      setSelectedTeam('')
      setGeneratedLink('') // Clear the generated link when modal is closed
    } else { 
      const DEFAULTFILENAME = 'DEFAULTFILENAME' // Replace with your actual default filename
      const selectedFile = localStorage.getItem('SELECTEDFILE')
      if (selectedFile && selectedFile !== DEFAULTFILENAME) {
        setFilename(selectedFile)
      } else {
        setFilename('')
      }
    }
  }, [visibleShare, setFilename, setGeneratedLink])

  const clearGeneratedLink = () => {
    setGeneratedLink('')
  }

  return (
    <CModal visible={visibleShare} onClose={() => setVisibleShare(false)}>
      <div style={{ display: 'flex', gap: '5px', flexDirection: 'column', margin: '10px' }}>
        <CModalTitle> Share</CModalTitle>
        <CFormSelect value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
          <option value="file">Share File</option>
          <option value="link">Share Diagram Link</option>
        </CFormSelect>
      </div>
      <hr />
      {selectedOption === 'file' && (
        <div>
          <CModalBody>
            <p>Details</p>
            <CFormInput
              type="text"
              placeholder="File Name"
              size="sm"
              value={filename}
              onChange={(e) => {
                setFilename(e.target.value)
              }}
            ></CFormInput>
            <br />
            <CFormSelect
              size="sm"
              className="mb-3"
              aria-label="Small select example"
              value={selectedTeam}
              onChange={(e) => {
                setSelectedTeam(e.target.value)
                handleSelectedTeam(e.target.value)
              }}
            >
              <option value="" disabled>
                {selectedTeam ? selectedTeam : 'Select a team or Create new team'}
              </option>
              {teamList && teamList.length > 0 ? (
                teamList.map((option, index) => (
                  <option key={index} value={teamList[index].teamId}>
                    {teamList[index].name}
                  </option>
                ))
              ) : (
                <option value="" disabled>
                  No Team
                </option>
              )}
            </CFormSelect>

            {selectedEmail && selectedEmail.length > 0 ? (
              <p>Team Emails: {selectedEmail.toString()}</p>
            ) : (
              <p></p>
            )}
            <p>Or</p>
            <CFormInput
              type="text"
              placeholder="Team Name"
              size="sm"
              onChange={(e) => {
                setTeamName(e.target.value)
              }}
            ></CFormInput>
            <br />
            <CFormInput
              type="text"
              placeholder="Enter multiple emails separated by comma/space"
              value={tag.join(', ')}
              onChange={(e) => handleTagChange(e.target.value)}
              onKeyDown={(e) => handleInputKeyDown(e)}
            />
          </CModalBody>
          <CModalFooter>
            <Button
              className="icon-button"
              onClick={() => {
                shareCodeToTeam({
                  fileName: filename,
                  orientation: direction,
                  diagramName: diagramName,
                  emailList: selectedEmail.length > 0 ? selectedEmail : tag,
                  name: teamName,
                  isExistingTeam: selectedEmail.length > 0 ? 1 : 0,
                })
                clearGeneratedLink()
              }}
            >
              Share
            </Button>
          </CModalFooter>
        </div>
      )}
      {selectedOption === 'link' && (
        <div>
          <CModalBody style={{ display: 'flex', gap: '5px', flexDirection: 'column' }}>
            <div style={{ display: 'flex', gap: '5px', justifyContent: 'space-between' }}>
              <Button
                className="icon-button"
                size="small"
                color="primary"
                onClick={() => {
                  handleShareDiagram()
                  clearGeneratedLink() // Clear the generated link input box
                }}
              >
                Generate Link
              </Button>
              <Tooltip title="Copy">
                <Button className="icon-button" size="small" onClick={CopyFunction}>
                  <ContentCopyIcon fontSize="small" />
                </Button>
              </Tooltip>
            </div>
            <CFormInput
              type="text"
              size="sm"
              value={generatedLink}
              placeholder="Diagram Link"
              aria-label="sm input example"
              readOnly
            />
          </CModalBody>
        </div>
      )}
    </CModal>
  )
}

export default ShareModal
