import React, { Component } from 'react'
import { BrowserRouter as HashRouter, Route, Routes } from 'react-router-dom'
import Landing from './Landing'
import './scss/style.scss'
import SubscriptionDetails from './views/subscription/Subscription'
import DefaultLayout from './layout/DefaultLayout'
import PricingPage from './views/stripe/PricingPage'
import Profile from './views/profile/Profile'
import Login from './views/pages/login/Login'
import Register from './views/pages/register/Register'
import Page404 from './views/pages/page404/Page404'
import Page500 from './views/pages/page500/Page500'
import ChatGPT from './components/appContent/ChatGPT'


class App extends Component {
  render() {
    return (
      <HashRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/subscription" element={<SubscriptionDetails />} />
          <Route path="/404" element={<Page404 />} />
          <Route path="/500" element={<Page500 />} />
          <Route path="/console" element={<DefaultLayout />} />
          <Route path="/ai" element={<ChatGPT onResponse={()=>{}} />} />
          
        </Routes>
      </HashRouter>
    )
  }
}

export default App
