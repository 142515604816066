import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined'
import TourOutlinedIcon from '@mui/icons-material/TourOutlined'
import ConnectWithoutContactOutlinedIcon from '@mui/icons-material/ConnectWithoutContactOutlined'
import SafetyCheckOutlinedIcon from '@mui/icons-material/SafetyCheckOutlined'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { AppHeaderDropdown } from './index'
import { Button, Menu, MenuItem, Tooltip } from '@mui/material'
import { Link } from 'react-router-dom'

const AppHeader = ({
  handleStartTour,
  user,
  isAuthenticated,
  logout,
  getAccessTokenSilently,
  handleUpgrade,
  manageLogin,
}) => {
  axios.defaults.withCredentials = true
  const [anchorEl, setAnchorEl] = useState(null)
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    ;(async () => {
      if (isAuthenticated && sessionStorage.getItem('userCreated') !== 'true') {
        const token = await getAccessTokenSilently()
        const data = {
          userId: user.sub,
          name: user.name,
          email: user.email,
        }
        axios
          .post(`${process.env.REACT_APP_SIDEBAR_API_URL}/api/diagram/user/create`, data, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            sessionStorage.setItem('userCreated', 'true')
          })
          .catch((error) => {
            console.log('Error:', error)
          })
      }
    })()
    ;(async () => {
      if (
        isAuthenticated &&
        (!sessionStorage.getItem('userFetched') ||
          sessionStorage.getItem('userFetched') === 'undefined')
      ) {
        const token = await getAccessTokenSilently()
        var userId = user.sub
        userId = userId.replace('|', '%7C')
        axios
          .get(`${process.env.REACT_APP_SIDEBAR_API_URL}/api/diagram/user/get/` + userId, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            const result = response.data.result
            if (result && result.length > 0) {
              sessionStorage.setItem('userFetched', JSON.stringify(result[0]))
              sessionStorage.setItem('userFetchedId', result[0].userId)
              sessionStorage.setItem('userFetchedEmail', result[0].email)
            }
          })
          .catch((err) => {
            console.log('Error occured ' + err.msg)
          })
      }
    })()
  }, [isAuthenticated])

  return (
    <header  className="header">
        <Link
          to="https://whiteboard.code2diagram.com"
          className="buttonStyles"
          style={{
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            fontSize: '14px',
            padding: '3px',
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          Whiteboard
        </Link>
        <Tooltip title="Help">
          <div>
            <Button className="buttonStyles" id="basic-button" onClick={handleMenuClick}>
              <InfoOutlinedIcon fontSize="small" />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem
                component="a"
                href="https://forms.gle/R56DQZDJUWaL4grJ6"
                target="_blank"
                className="mode_menu"              >
                <ContactPageOutlinedIcon fontSize="small" style={{ marginRight: '5px' }}/>
                Contact Us
              </MenuItem>
              <MenuItem
                component="a"
                href="https://forms.gle/R56DQZDJUWaL4grJ6"
                target="_blank"
                className="mode_menu"              >
                <AddBoxOutlinedIcon fontSize="small" style={{ marginRight: '5px' }} />
                Request a Feature
              </MenuItem>
              <MenuItem
                component="a"
                href="https://forms.gle/R56DQZDJUWaL4grJ6"
                target="_blank"
                className="mode_menu"              >
                <ReportProblemOutlinedIcon fontSize="small" style={{ marginRight: '5px' }} />
                Report a bug
              </MenuItem>
              <MenuItem
                component="a"
                href="https://forms.gle/R56DQZDJUWaL4grJ6"
                target="_blank"
                className="mode_menu"              >
                <ConnectWithoutContactOutlinedIcon fontSize="small" style={{ marginRight: '5px' }}/>
                Contact Us for Enterprise License
              </MenuItem>
              <MenuItem
                component="a"
                href="https://code2diagram.com/privacyPolicy.html"
                target="_blank"
                className="mode_menu"              >
                <SafetyCheckOutlinedIcon fontSize="small" style={{ marginRight: '5px' }}/>
                Privacy Policy
              </MenuItem>
              <MenuItem
                component="a"
                href="https://code2diagram.com/tutorial/examples/index.html"
                target="_blank"
                className="mode_menu"              >
                <LocalLibraryOutlinedIcon fontSize="small" style={{ marginRight: '5px' }}/>
                Tutorial
              </MenuItem>
              <MenuItem onClick={handleStartTour} className="mode_menu">
                <TourOutlinedIcon fontSize="small" style={{ marginRight: '5px' }}/>
                App Tour
              </MenuItem>
            </Menu>
          </div>
        </Tooltip>
        <AppHeaderDropdown
          user={user}
          isAuthenticated={isAuthenticated}
          logout={logout}
          getAccessTokenSilently={getAccessTokenSilently}
          handleUpgrade={handleUpgrade}
          manageLogin={manageLogin}
        />
    </header>
  )
}

export default AppHeader
